// --------------------------------------Padding--------------------------------------------------
// .p-10 {
//     padding: 6rem;
// }

.pt-10 {
    padding-top: 6rem;
}

.pb-10 {
    padding-bottom: 6rem;
}

// .ps-10 {
//     padding-left: 6rem;
// }

// .pe-10 {
//     padding-right: 6rem;
// }

.m-10 {
    margin: 6rem;
}

// .mt-10 {
//     margin-top: 6rem;
// }

.mb-10 {
    margin-bottom: 6rem;
}

.my-10 {
    margin: 6rem 0;
}

// .ms-10 {
//     margin-left: 6rem;
// }

// .me-10 {
//     margin-right: 6rem;
// }
// -----------------------------------------------------------------------------------------------