// Text Colors

// Primary color variants
@mixin color($color) {
    color: $color;
}

.color_primary {
    &.v1 {
        @include color(map-get(map-get($palette, primary), 1));
    }
    &.v2 {
        @include color(map-get(map-get($palette, primary), 2));
    }
}

.color_black {
    @include color(map-get(map-get($palette, dark), 1));
}

.color_muted {
    @include color(map-get(map-get($palette, secondary), 2));
}

.color_danger {
    @include color(map-get(map-get($palette, danger), 1));
}

.color_success {
    @include color(map-get(map-get($palette, success), 1));
}

// -----------------------------------------------------------------------

// Text Sizes
@mixin fontSize($size) {
    font-size: $size;
}

.font_size {
    &.vw1 {
        @include fontSize(map-get($fontSizeVW, 1));
    }
    &.vw2 {
        @include fontSize(map-get($fontSizeVW, 2));
    }
    &.vw3 {
        @include fontSize(map-get($fontSizeVW, 3));
    }
    &.vw4 {
        @include fontSize(map-get($fontSizeVW, 4));
    }
    &.vw5 {
        @include fontSize(map-get($fontSizeVW, 5));
    }
    &.vw6 {
        @include fontSize(map-get($fontSizeVW, 6));
    }
    &.regular {
        @include fontSize(map-get($fontSize, regular));
    }
}

// ------------------------------------------------------------------------

// Background color
@mixin bgColor($bgColor) {
    background-color: $bgColor;
}

.bg {
    &.white {
        @include bgColor(map-get(map-get($palette, light), 1));
    }
}
// --------------------------------------------------------------------------

p {
    &.pdesc {
        color: map-get(map-get($palette, primary), 2);
        font-size: 0.9375rem;
        font-weight: 400;
        line-height: 1.25rem;
    }
}