// Outline buttons
@mixin btnVariant(
    $background,
    $border,
    $color,
    $hover-background,
    $hover-border,
    $hover-color,
    $disabled-background,
    $disabled-border,
    $disabled-color,
    $padding,
    $radius: 0.25rem,
    $box-shadow: none
) {
    background-color: $background;
    color: $color;
    @if $border != 0 {
        border: $border;
    }
    border-radius: $radius;
    padding: $padding;

    &:hover {
        color: $hover-color;
        background-color: $hover-background;
        border-color: $hover-border;
    }

    &:disabled {
        color: $disabled-color;
        background-color: $disabled-background;
        border-color: $disabled-border;
        opacity: 0.5;
    }
}

@mixin btnState($bgColor, $borderColor, $color) {
    background-color: $bgColor;
    border-color: $borderColor;
    color: $color;
}

.btn_primary_outline {
    font-size: map-get($fontSize, regular);
    font-weight: 500;
    transition: 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.25rem;

    @include btnVariant(
        // normal
        map-get(map-get($palette, light), 1),
        $border-thin,
        map-get(map-get($palette, primary), 1),
        // hover
        map-get(map-get($palette, primary), 1),
        map-get(map-get($palette, primary), 1),
        map-get(map-get($palette, light), 1),
        // disabled
        map-get(map-get($palette, light), 1),
        map-get(map-get($palette, primary), 1),
        map-get(map-get($palette, primary), 1),
        // others
        5px 15px 4px
    );

    &.no-hover {
        &:hover {
            @include btnState(map-get(map-get($palette, light), 1), map-get(map-get($palette, primary), 1), map-get(map-get($palette, primary), 1));
        }
    }

    &:enabled {
        &:active, &:focus {
            @include btnState(map-get(map-get($palette, light), 1), map-get(map-get($palette, primary), 1), map-get(map-get($palette, primary), 1));
        }
    }
}

.btn_primary_success {
    font-size: map-get($fontSize, regular);
    font-weight: 500;
    transition: 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.25rem;

    @include btnVariant(
        // normal
        map-get(map-get($palette, light), 1),
        $border-thin,
        map-get(map-get($palette, success), 1),
        // hover
        map-get(map-get($palette, success), 1),
        map-get(map-get($palette, success), 1),
        map-get(map-get($palette, light), 1),
        // disabled
        map-get(map-get($palette, light), 1),
        map-get(map-get($palette, success), 1),
        map-get(map-get($palette, success), 1),
        // others
        5px 15px 4px
    );

    &.no-hover {
        &:hover {
            @include btnState(map-get(map-get($palette, light), 1), map-get(map-get($palette, success), 1), map-get(map-get($palette, success), 1));
        }
    }

    &:enabled {
        &:active, &:focus {
            @include btnState(map-get(map-get($palette, light), 1), map-get(map-get($palette, success), 1), map-get(map-get($palette, success), 1));
        }
    }
}

.primary_warning_outline {
    font-size: map-get($fontSize, regular);
    font-weight: 500;
    transition: 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.25rem;

    @include btnVariant(
        // normal
        map-get(map-get($palette, light), 1),
        $border-thin,
        map-get(map-get($palette, danger), 1),
        // hover
        map-get(map-get($palette, danger), 1),
        map-get(map-get($palette, danger), 1),
        map-get(map-get($palette, light), 1),
        // disabled
        map-get(map-get($palette, light), 1),
        map-get(map-get($palette, danger), 1),
        map-get(map-get($palette, danger), 1),
        // others
        5px 15px 4px
    );

    &.no-hover {
        &:hover {
            @include btnState(map-get(map-get($palette, light), 1), map-get(map-get($palette, danger), 1), map-get(map-get($palette, danger), 1));
        }
    }

    &:enabled {
        &:active, &:focus {
            @include btnState(map-get(map-get($palette, light), 1), map-get(map-get($palette, danger), 1), map-get(map-get($palette, danger), 1));
        }
    }
}

.btn_primary {
    font-size: map-get($fontSize, regular);
    font-weight: 500;
    transition: 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.25rem;
    
    @include btnVariant(
        // normal
        map-get(map-get($palette, primary), 1.1),
        $border-thin,
        map-get(map-get($palette, light), 1),
        // hover
        map-get(map-get($palette, primary), 1) !important,
        map-get(map-get($palette, light), 1) !important,
        map-get(map-get($palette, light), 1) !important,
        // disabled
        map-get(map-get($palette, primary), 1),
        map-get(map-get($palette, light), 1),
        map-get(map-get($palette, light), 1),
        // others
        5px 15px 4px
    );

    &:enabled {
        &:active, &:focus {
            @include btnState(map-get(map-get($palette, primary), 1), map-get(map-get($palette, primary), 1), map-get(map-get($palette, light), 1));
        }
    }
}