* {
    box-sizing: border-box;
    &:active,
    &:hover,
    &:focus {
        outline: 0;
    }
}

html {
    font-size: 17px;
    height: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
}

body {
    font-size: map-get($fontSize, regular);
    margin: 0;
    padding: 0;
    font-weight: 400;
    line-height: 1.5;
    color: map-get(map-get($palette, dark), 1);
    text-align: left;
    background-color: map-get(map-get($palette, light), 1);
    font-family: $font-family-primary;
    font-style: normal;

    &::-webkit-scrollbar {
        width: 0.3em;
    }

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    &::-webkit-scrollbar-thumb {
        background-color: map-get(map-get($palette, secondary), 3);
        outline: $border-thin map-get(map-get($palette, secondary), 7);
    }
}

button,
p,
a,
span,
label,
.label,
.p-inputtext,
.p-button-label,
.p-dropdown-item,
.paragraph,
h1,
h2,
h3,
h4,
h5,
h6,
.p-component,
.p-inputtext,
.p-card,
.payment-form {
    font-family: $font-family-primary;
    font-style: normal;
}
