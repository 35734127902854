.dropdown-control {
    &.p-dropdown {
        border: $border-thin map-get(map-get($palette, secondary), 4);

        .p-dropdown-label {
            &.p-placeholder {
                color: map-get(map-get($palette, secondary), 2);
            }
        }
    }
}

.checkbox-primary {
    &.p-checkbox {
        .p-highlight {
            border-color: map-get(map-get($palette, primary), 1);
            background: map-get(map-get($palette, primary), 1);
        }
    }
}

label {
    &.label_muted {
        font-size: 0.75rem;
        line-height: 1rem;
        color: #8f9bb3;
        font-weight: 700;
    }
    &.label_primary {
        color: #222b45;
        font-size: .9375rem;
        font-weight: 700;
        line-height: 1.25rem;
    }
}