@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap");
@import url("https://rsms.me/inter/inter.css");

$font-family-primary: "Inter";
// $font-family-secondary: "Raleway", sans-serif;

// border
$border-thin: 1px solid !default;
$border-thick: 2px solid !default;

// card
$card-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
$card-box-shadow-hover: 0 8px 16px 0 rgba(0, 0, 0, 0.2);

// color palette
$palette: (
    primary: (
        1: #1a76b9,
        1.1: #1a76b9cc,
        2: #222b45,
        3: #212529,
        4: #eaf6ff,
        5: #3177bb,
        6: #04021d
    ),
    secondary: (
        1: #425d68,
        2: #737c7d,
        3: #9e9e9e,
        4: #cfd4d9,
        5: #edf2f3,
        6: #f6f8f9,
        7: #ebebeb,
        8: #666666,
        9: #F2F5F6,
        10: #E9F2F8,
    ),
    light: (
        1: #ffffff,
    ),
    dark: (
        1: #231f20,
    ),
    danger: (
        1: #dc3545,
        4: #fde8e8,
    ),
    warn: (
        1: #fbc400,
        2: #fff2c6,
        3: #5f5123,
        4: #fdfce8,
    ),
    success: (
        1: #198754,
        4: #e8f7f0,
    ),
    // orange: (
    //     1: #fb7800,
    //     2: #fdf2e8,
    // ),
    rose: (
        1: #9c38d9,
        4: #f6ecfc,
    ),
    indigo: (
        base: #3f51b5,
    ),
);

$fontSize: (
	// xxx-sm: 0.5rem,         // 8px
	// xx-sm: 0.625rem,        // 10px
	x-sm: 0.75rem,          // 12px
	// sm: 0.875rem,           // 14px
	regular: 1rem,          // 16px
	// md: 1.125rem,           // 18px
	// lg: 1.25rem,            // 20px
	// x-lg: 1.375rem,         // 22px
	// xx-lg: 1.5rem,          // 24px
	// xxx-lg: 1.625rem,       // 26px
	// huge: 1.75rem,          // 28px
	// x-huge: 1.875rem,       // 30px
	// xx-huge: 2rem,          // 32px
	// xxx-huge: 2.125rem,     // 34px
	// mighty: 2.25rem,        // 36px
);

$fontSizeVW: (
    1: 1vw,
    2: 2vw,
    3: 3vw,
    4: 4vw,
    5: 5vw,
    6: 6vw
);