.cb { clear: both; }
.pointer { cursor: pointer; }
.rel { position: relative; }
.fs-1vw { font-size: 1vw; }
.width-1vw { width: 1vw; }
.width-2vw { width: 2vw; }
.round { border-radius: 50px; }
.line-break-anywhere { line-break: anywhere }
.v-align-base { vertical-align: baseline; }

.disabled {
    background-color: #ddd!important;
    border-color: #bbb!important;
}

small {
    font-size: 80%;
    font-weight: 400;
}

.mw-300 {
    max-width: 300px;
}

.smw-750 {
    max-width: 750px;
    margin: auto;
}

.smw-450 {
    max-width: 450px;
    margin: auto;
}

.mw-350 {
    max-width: 350px;
}

.mw-550 {
    max-width: 550px;
}

.smw-820 {
    max-width: 820px;
    margin: auto;
}

a {
    text-decoration: none;
    color: map-get(map-get($palette, primary), 1.1);

    &:hover {
        color: map-get(map-get($palette, primary), 1); 
    }
}


.list-group-flush {
    .list-group-item {
        border-width: 1px;
        border-right: 0;
        border-left: 0;
        border-radius: 0;
    }
}

.list-group-item {
    position: relative;
    display: block;
    padding: 0.75rem 1.25rem;
    margin-bottom: -1px;
    background-color: map-get(map-get($palette, light), 1);
    border: 1px solid rgba(0,0,0,.125);

    &.list-group-item-action {
        color: #495057;
    }
}

.badge-danger {
    .p-badge {
        background: map-get(map-get($palette, danger), 1);
    }
}