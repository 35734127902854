@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap");
@import url("https://rsms.me/inter/inter.css");
* {
  box-sizing: border-box;
}
*:active, *:hover, *:focus {
  outline: 0;
}

html {
  font-size: 17px;
  height: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  font-size: 1rem;
  margin: 0;
  padding: 0;
  font-weight: 400;
  line-height: 1.5;
  color: #231f20;
  text-align: left;
  background-color: #ffffff;
  font-family: "Inter";
  font-style: normal;
}
body::-webkit-scrollbar {
  width: 0.3em;
}
body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
body::-webkit-scrollbar-thumb {
  background-color: #9e9e9e;
  outline: 1px solid #ebebeb;
}

button,
p,
a,
span,
label,
.label,
.p-inputtext,
.p-button-label,
.p-dropdown-item,
.paragraph,
h1,
h2,
h3,
h4,
h5,
h6,
.p-component,
.p-inputtext,
.p-card,
.payment-form {
  font-family: "Inter";
  font-style: normal;
}

.color_primary.v1 {
  color: #1a76b9;
}
.color_primary.v2 {
  color: #222b45;
}

.color_black {
  color: #231f20;
}

.color_muted {
  color: #737c7d;
}

.color_danger {
  color: #dc3545;
}

.color_success {
  color: #198754;
}

.font_size.vw1 {
  font-size: 1vw;
}
.font_size.vw2 {
  font-size: 2vw;
}
.font_size.vw3 {
  font-size: 3vw;
}
.font_size.vw4 {
  font-size: 4vw;
}
.font_size.vw5 {
  font-size: 5vw;
}
.font_size.vw6 {
  font-size: 6vw;
}
.font_size.regular {
  font-size: 1rem;
}

.bg.white {
  background-color: #ffffff;
}

p.pdesc {
  color: #222b45;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.25rem;
}

.btn_primary_outline {
  font-size: 1rem;
  font-weight: 500;
  transition: 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
  background-color: #ffffff;
  color: #1a76b9;
  border: 1px solid;
  border-radius: 0.25rem;
  padding: 5px 15px 4px;
}
.btn_primary_outline:hover {
  color: #ffffff;
  background-color: #1a76b9;
  border-color: #1a76b9;
}
.btn_primary_outline:disabled {
  color: #1a76b9;
  background-color: #ffffff;
  border-color: #1a76b9;
  opacity: 0.5;
}
.btn_primary_outline.no-hover:hover {
  background-color: #ffffff;
  border-color: #1a76b9;
  color: #1a76b9;
}
.btn_primary_outline:enabled:active, .btn_primary_outline:enabled:focus {
  background-color: #ffffff;
  border-color: #1a76b9;
  color: #1a76b9;
}

.btn_primary_success {
  font-size: 1rem;
  font-weight: 500;
  transition: 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
  background-color: #ffffff;
  color: #198754;
  border: 1px solid;
  border-radius: 0.25rem;
  padding: 5px 15px 4px;
}
.btn_primary_success:hover {
  color: #ffffff;
  background-color: #198754;
  border-color: #198754;
}
.btn_primary_success:disabled {
  color: #198754;
  background-color: #ffffff;
  border-color: #198754;
  opacity: 0.5;
}
.btn_primary_success.no-hover:hover {
  background-color: #ffffff;
  border-color: #198754;
  color: #198754;
}
.btn_primary_success:enabled:active, .btn_primary_success:enabled:focus {
  background-color: #ffffff;
  border-color: #198754;
  color: #198754;
}

.primary_warning_outline {
  font-size: 1rem;
  font-weight: 500;
  transition: 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
  background-color: #ffffff;
  color: #dc3545;
  border: 1px solid;
  border-radius: 0.25rem;
  padding: 5px 15px 4px;
}
.primary_warning_outline:hover {
  color: #ffffff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.primary_warning_outline:disabled {
  color: #dc3545;
  background-color: #ffffff;
  border-color: #dc3545;
  opacity: 0.5;
}
.primary_warning_outline.no-hover:hover {
  background-color: #ffffff;
  border-color: #dc3545;
  color: #dc3545;
}
.primary_warning_outline:enabled:active, .primary_warning_outline:enabled:focus {
  background-color: #ffffff;
  border-color: #dc3545;
  color: #dc3545;
}

.btn_primary {
  font-size: 1rem;
  font-weight: 500;
  transition: 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
  background-color: rgba(26, 118, 185, 0.8);
  color: #ffffff;
  border: 1px solid;
  border-radius: 0.25rem;
  padding: 5px 15px 4px;
}
.btn_primary:hover {
  color: #ffffff !important;
  background-color: #1a76b9 !important;
  border-color: #ffffff !important;
}
.btn_primary:disabled {
  color: #ffffff;
  background-color: #1a76b9;
  border-color: #ffffff;
  opacity: 0.5;
}
.btn_primary:enabled:active, .btn_primary:enabled:focus {
  background-color: #1a76b9;
  border-color: #1a76b9;
  color: #ffffff;
}

.dropdown-control.p-dropdown {
  border: 1px solid #cfd4d9;
}
.dropdown-control.p-dropdown .p-dropdown-label.p-placeholder {
  color: #737c7d;
}

.checkbox-primary.p-checkbox .p-highlight {
  border-color: #1a76b9;
  background: #1a76b9;
}

label.label_muted {
  font-size: 0.75rem;
  line-height: 1rem;
  color: #8f9bb3;
  font-weight: 700;
}
label.label_primary {
  color: #222b45;
  font-size: 0.9375rem;
  font-weight: 700;
  line-height: 1.25rem;
}

.prime-progress .p-progressbar {
  background: #eaf6ff;
}
.prime-progress .p-progressbar .p-progressbar-value {
  background: #1a76b9;
}

.cb {
  clear: both;
}

.pointer {
  cursor: pointer;
}

.rel {
  position: relative;
}

.fs-1vw {
  font-size: 1vw;
}

.width-1vw {
  width: 1vw;
}

.width-2vw {
  width: 2vw;
}

.round {
  border-radius: 50px;
}

.line-break-anywhere {
  line-break: anywhere;
}

.v-align-base {
  vertical-align: baseline;
}

.disabled {
  background-color: #ddd !important;
  border-color: #bbb !important;
}

small {
  font-size: 80%;
  font-weight: 400;
}

.mw-300 {
  max-width: 300px;
}

.smw-750 {
  max-width: 750px;
  margin: auto;
}

.smw-450 {
  max-width: 450px;
  margin: auto;
}

.mw-350 {
  max-width: 350px;
}

.mw-550 {
  max-width: 550px;
}

.smw-820 {
  max-width: 820px;
  margin: auto;
}

a {
  text-decoration: none;
  color: rgba(26, 118, 185, 0.8);
}
a:hover {
  color: #1a76b9;
}

.list-group-flush .list-group-item {
  border-width: 1px;
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item.list-group-item-action {
  color: #495057;
}

.badge-danger .p-badge {
  background: #dc3545;
}

.pt-10 {
  padding-top: 6rem;
}

.pb-10 {
  padding-bottom: 6rem;
}

.m-10 {
  margin: 6rem;
}

.mb-10 {
  margin-bottom: 6rem;
}

.my-10 {
  margin: 6rem 0;
}

.dialog-primary .p-dialog-header {
  background: #1a76b9;
  color: #ffffff;
}
.dialog-primary.title-centered .p-dialog-header {
  justify-content: center;
}