.dialog-primary {
    .p-dialog-header {
        background: map-get(map-get($palette, primary), 1);
        color: map-get(map-get($palette, light), 1);
    }

    &.title-centered {
        .p-dialog-header {
            justify-content: center;
        }
    }
}